import React, { useEffect, useState } from 'react';
import './index.scss';
import { Button, Card, Flex, Layout } from 'antd';
import HeaderComponent from '../../components/core/header/Index';
import { Content } from 'antd/es/layout/layout';
import CarouselContent from '../../components/carousel-content/Index';
import { getColorsFromConfig, getConfigJson, getConfigString } from '../../services/remote-config/config';
import ScrollTextEffect from '../../components/core/scroll-text-effect/Index';
import FooterComponent from '../../components/core/footer/Index';
import Packs from '../../components/packs/Index';
import HowItWorks from '../../components/how-it-works/Index';
import MenuGallery from '../../components/menu-gallery/Index';
import { ga } from '../../services/analytics/analytics';

const HomePage: React.FC = () => {
    const [deliveryDays, setDeliveryDays] = useState('');
    const [company, setCompany] = useState<({email: string, tel: string, cities: string} | null)>(null);
    
    const event = (name: string) => {
        ga(name, {
            path: window.location.href, 
            title: document.title, 
            userType: 'visitor',
            userAgent: navigator.userAgent
        });
    }
    
    // action on load page
    useEffect(() => {
        document.title = "TLAC / Comida saludable";
        getColorsFromConfig();
        getConfigString('deliveryDays', setDeliveryDays);
        getConfigJson('company', setCompany);
        ga('view-home', {
            path: window.location.href, 
            title: document.title, 
            userType: 'visitor',
            userAgent: navigator.userAgent
        });
    }, []);

    return (<Layout className='home page'>
        <HeaderComponent hasMenu />
        <Content>
            <Flex className='us'>
                <div className='us-desc' id='Nosotros'>
                    <span>Comida Sana a Domicilio</span>
                    <p>En Tlac, sabemos lo difícil que es mantener una alimentación saludable con un estilo de vida ocupado. Por eso, en Tijuana, Baja California, ofrecemos un servicio de comida a domicilio saludable que te permite planificar tus comidas semanales con facilidad. Con meal preps frescos y adaptados a tus necesidades, solo pide con una semana de anticipación y disfruta de comida saludable directamente en tu puerta. Tú te enfocas en lo importante; nosotros cuidamos tu nutrición.</p>
                    <Button className='shop-now' type='primary' href='https://app.tlac.com.mx' onClick={() => event('go-to-store')}>Comprar Ahora</Button>
                </div>
                <div className='us-pic'></div>
            </Flex>
            {/* Small Intro */}
            <CarouselContent name='main' count={6} />
            <ScrollTextEffect speed={10} direction='left-to-right' startFrom='left' initY={100} endY={1000}>Comida Sana</ScrollTextEffect>
            <h1 className='section1'>
            Mantén tus objetivos de salud al alcance. Planifica tu semana con Tlac y recibe comidas saludables a domicilio, adaptadas a tus necesidades y estilo de vida. Disfruta de comidas frescas y nutritivas sin complicaciones.
            </h1>
            <ScrollTextEffect speed={10} direction='right-to-left' startFrom='right' initY={100} endY={1000}>Facil y Delicioso</ScrollTextEffect>

            {/* Price by packages here */}
            <Flex className='section2' vertical gap={50} id='Precios'>
                <span className='title'>Explora Nuestros Paquetes Semanales</span>
                <Packs />
                {/* <h3>Si te gustaria una cotizacion personalizada puedes consultar nuestra <a href='/Calculator' onClick={() => event('go-to-calculator')}>Calculadora de Precios</a></h3> */}
            </Flex>
            {/* How it works */}
            <Flex className='section3' vertical gap={50} id='Comofunciona'>
                <ScrollTextEffect speed={10} direction='left-to-right' startFrom='left' initY={1481} endY={3400}>Simple</ScrollTextEffect>
                <span className='title'>¿Cómo funciona nuestro servicio?</span>
                <HowItWorks />
            </Flex>
            <ScrollTextEffect speed={10} direction='right-to-left' startFrom='right' initY={1500} endY={3400}>Económico</ScrollTextEffect>
            <Flex className='section4' vertical>
                <span className='title'>Entregas</span>
                <p>
                    Nosotros entregamos cada semana los días <strong className='highlighted-text'>{deliveryDays}</strong> cada pedido que se realizo con 1 semana de anticipación, 
                    nuestros horarios de entrega varian dependiendo de tu ubicacion y preferencias.
                </p>
                <p>Actualmente disponibles en <strong className='highlighted-text'>{company?.cities}</strong>.</p>
                <Button type='primary' className='action-btn' href='https://app.tlac.com.mx' onClick={() => event('go-to-store')}>Hacer mi pedido ya!</Button>
                <div className='blur-background'></div>
            </Flex>
            <ScrollTextEffect speed={10} direction='left-to-right' startFrom='left' initY={4200} endY={4800}>Saludable</ScrollTextEffect>
            <Flex className='section5' vertical id='Menu'>
                <span className='title'>Algunos de Nuestros Platillos</span>
                <MenuGallery />
            </Flex>
            <ScrollTextEffect speed={10} direction='right-to-left' startFrom='right' initY={4300} endY={4800}>Delicioso</ScrollTextEffect>

            <Flex className='section6' vertical id='Contacto' justify='center'>
                <Card title={<h1>Contacto</h1>} style={{maxWidth: 400, alignSelf: 'center'}}>
                    <p>¿Tienes alguna pregunta o necesitas más información? ¡Contáctanos! Estamos aquí para ayudarte.</p>
                    <div className="contact-info">
                        <p><strong>Teléfono:</strong> {company?.tel}</p>
                        <p><strong>Correo electrónico:</strong> {company?.email}</p>
                        <p><strong>Ubicación:</strong> Tlac ofrece su servicio de entrega de kits de comida en diversas áreas de {company?.cities}. Para más detalles sobre la cobertura, contáctanos.</p>
                    </div>
                </Card>
            </Flex>


        </Content>
        <FooterComponent />
    </Layout>);
}

export default HomePage;